var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        title: "操作",
        width: 800,
        visible: _vm.visible,
        confirmLoading: _vm.confirmLoading,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "唯一识别码",
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["id", { rules: [] }],
                        expression: "[ 'id', {rules: []} ]",
                      },
                    ],
                    attrs: { placeholder: "唯一识别码", disabled: "disabled" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "角色名称",
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "name",
                          {
                            rules: [
                              { required: true, message: "不起一个名字吗？" },
                            ],
                          },
                        ],
                        expression:
                          "[ 'name', {rules: [{ required: true, message: '不起一个名字吗？' }] }]",
                      },
                    ],
                    attrs: { placeholder: "起一个名字" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "状态",
                    hasFeedback: "",
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["status", { rules: [] }],
                          expression: "[ 'status', {rules: []} ]",
                        },
                      ],
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("正常"),
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("禁用"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "描述",
                    hasFeedback: "",
                  },
                },
                [
                  _c("a-textarea", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["describe", { rules: [] }],
                        expression: "[ 'describe', { rules: [] } ]",
                      },
                    ],
                    attrs: { rows: 5, placeholder: "..." },
                  }),
                ],
                1
              ),
              _c("a-divider"),
              _c(
                "a-form-item",
                {
                  attrs: {
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                    label: "拥有权限",
                    hasFeedback: "",
                  },
                },
                _vm._l(_vm.permissions, function (permission, index) {
                  return _c(
                    "a-row",
                    { key: index, attrs: { gutter: 16 } },
                    [
                      _c("a-col", { attrs: { span: 4 } }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(permission.name) +
                            "：\n          "
                        ),
                      ]),
                      _c(
                        "a-col",
                        { attrs: { span: 20 } },
                        [
                          permission.actionsOptions.length > 0
                            ? _c(
                                "a-checkbox",
                                {
                                  attrs: {
                                    indeterminate: permission.indeterminate,
                                    checked: permission.checkedAll,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.onChangeCheckAll(
                                        $event,
                                        permission
                                      )
                                    },
                                  },
                                },
                                [_vm._v("\n              全选\n            ")]
                              )
                            : _vm._e(),
                          _c("a-checkbox-group", {
                            attrs: { options: permission.actionsOptions },
                            on: {
                              change: function ($event) {
                                return _vm.onChangeCheck(permission)
                              },
                            },
                            model: {
                              value: permission.selected,
                              callback: function ($$v) {
                                _vm.$set(permission, "selected", $$v)
                              },
                              expression: "permission.selected",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }